import { EnergyStorageDevice, EnergyStorageDeviceRequest } from '@/types/infrastructure';
import { formatNumber } from '@/util/formatNumber';
import { formatDate } from '@/util/formatting';

export const mapEnergyStorageDevices = (energyStorageDevices: EnergyStorageDeviceRequest[]): EnergyStorageDevice[] =>
    energyStorageDevices.map((device) => ({
        ...device,
        electricityMeter: device.electricityMeter ?? '',
        capacity: formatNumber(device.capacity),
        commissioningDate: device.commissioningDate ? formatDate(device.commissioningDate) : undefined,
    }));
