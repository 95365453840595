import { appConfig } from '@/app.config';
import { Availability } from '@/types/availability.ts';
import axios, { AxiosResponse, GenericAbortSignal } from 'axios';
import { LatLng } from 'leaflet';

export type CheckAvailabilityResponse = {
    electricity: Availability;
    cadastralDistrictCoordinates?: LatLng[];
};

const getValidQueryCoordinates = (coordinates: LatLng[]): LatLng[] =>
    coordinates.filter(
        (coordinate) =>
            coordinate.lat !== undefined &&
            coordinate.lng !== undefined &&
            coordinate.lat !== null &&
            coordinate.lng !== null,
    );

export const checkAvailability = async (
    coordinates: LatLng[],
    signal?: GenericAbortSignal,
): Promise<CheckAvailabilityResponse> => {
    const response: AxiosResponse<CheckAvailabilityResponse> = await axios.post<CheckAvailabilityResponse>(
        `${appConfig.services.controllableConsumptionDevicesApi}/cadastral-district/availability`,
        {
            coordinates: getValidQueryCoordinates(coordinates),
        },
        {
            signal,
        },
    );

    return response.data;
};
