import { appConfig } from '@/app.config';
import { ApiParams } from '@/hooks/useApi';
import { ConnectionDataPropertiesRequest } from '@/types/connectionData';
import { InfrastructureDevicesRequest } from '@/types/infrastructure';
import { PlantOperator } from '@/types/plantOperatorObject';
import { InstallerFilterResult } from '@ten-netzkundenportal/ui-installer-selection';
import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';

type Params = {
    processCommunicationId: string;
};

export type OpenControllableConsumptionDevicesRegistration = {
    processCommunicationId: string;
    id: string;

    customerType: 'private' | 'company';
    location: AddressLocation | CadastralDistrictLocation;
    plantOperator: PlantOperator;
    chosenInstaller?: InstallerFilterResult;
    infrastructure: Pick<InfrastructureDevicesRequest, 'energyStorageDevices'>;
    connectionDataProperties: ConnectionDataPropertiesRequest;
};

export const getOpenControllableConsumptionDevicesRegistration = async ({
    customerId,
    axios,
    params: { processCommunicationId },
}: ApiParams<Params>): Promise<OpenControllableConsumptionDevicesRegistration> => {
    const response = await axios.get<OpenControllableConsumptionDevicesRegistration>(
        `${appConfig.services.controllableConsumptionDevicesApi}/account/${customerId}/open-controllable-consumption-devices-registration/${processCommunicationId}`,
    );
    return response.data;
};
