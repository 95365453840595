import { getSas } from '@/api/getSas';
import { appConfig } from '@/app.config';
import { DocumentMetadata } from '@/finished-registration/documents/types/DocumentMetadata';
import useApi from '@/hooks/useApi';
import { downloadBlob, useContainerClientWrapper } from '@ten-netzkundenportal/ui-document-upload';
import { useCallback } from 'react';

export type UseDownloadProps = {
    registrationId: string;
};

export const useDownload = ({ fileName, documentId, type, registrationId }: DocumentMetadata & UseDownloadProps) => {
    const createSasApi = useApi(getSas);

    const createSasCallback = useCallback(() => createSasApi({ registrationId }), [createSasApi, registrationId]);

    const containerClientWrapper = useContainerClientWrapper({
        storageApiUrl: appConfig.services.controllableConsumptionDevicesUserUploadApi,
        containerName: registrationId.toLowerCase(),
        createSas: createSasCallback,
    });

    const downloadDocumentFromBlobStorage = useCallback(async () => {
        const { blob, contentType } = await containerClientWrapper.downloadBlob(documentId);
        downloadBlob(blob, contentType, fileName);
    }, [containerClientWrapper, documentId, fileName]);

    const download = useCallback(() => {
        if (type === 'blob') {
            return downloadDocumentFromBlobStorage();
        }

        throw new Error(`Invalid type ${type}`);
    }, [downloadDocumentFromBlobStorage, type]);

    return {
        download,
    };
};
