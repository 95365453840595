import { OpenRegistrationInitiatedFrom } from '@/types/metaObject';

export function mapReferrerToOpenRegistrationInitiatedFrom(
    referrer: string,
): OpenRegistrationInitiatedFrom | undefined {
    switch (referrer) {
        case 'uebersicht':
            return 'openRegistrationFromDashboard';
        case 'einspeisung-naechste-schritte':
            return 'redirectAfterDistributedGenerationRequest';
        default:
            return undefined;
    }
}
