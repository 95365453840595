import { isAddressLocation } from '@/types/locationData/guards';
import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';
import L from 'leaflet';

export const mapLocationToGeoCoordinates = (location: AddressLocation | CadastralDistrictLocation) => {
    if (isAddressLocation(location)) {
        return [L.latLng(location.geojson.coordinates[1], location.geojson.coordinates[0])];
    }
    const cadastralDistrict = location.geojson.coordinates[0];
    return cadastralDistrict.map((coordinates) => L.latLng(coordinates[1], coordinates[0]));
};
