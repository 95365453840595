import {
    ADDRESS_PLANT_OPERATOR,
    DEVICE_DATA_CONNECTION_PLAN,
    DEVICE_DATA_CONNECTION_VARIANT,
    DEVICE_DATA_GRID_FEES_REDUCTION,
    DEVICE_DATA_INFRASTRUCTURE,
    DEVICE_DATA_INSTALLER,
    DEVICE_DATA_LOCATION_DATA,
    DOCUMENT_UPLOAD,
    NEXT_STEPS,
    NOT_RESPONSIBLE,
    NO_CONTROLLABLE_CONSUMPTION_DEVICES_DETECTED,
    REQUEST_OVERVIEW,
} from '@/routes';

export const STEPS = {
    DEVICE_DATA: 'DEVICE_DATA_PROCESS',
    ADDRESS: 'ADDRESS_PROCESS',
    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
    REQUEST_OVERVIEW: 'REQUEST_OVERVIEW_PROCESS',
    NEXT_STEPS: 'NEXT_STEPS_PROCESS',
} as const;

export type StepsValue = (typeof STEPS)[keyof typeof STEPS];

export const ALL_STEPS: StepsValue[] = [
    STEPS.DEVICE_DATA,
    STEPS.ADDRESS,
    STEPS.DOCUMENT_UPLOAD,
    STEPS.REQUEST_OVERVIEW,
    STEPS.NEXT_STEPS,
];

export type SubprocessValue =
    | 'NOT_RESPONSIBLE'
    | 'NO_CONTROLLABLE_CONSUMPTION_DEVICES_DETECTED'
    | 'DEVICE_DATA_LOCATION_DATA'
    | 'DEVICE_DATA_CONNECTION_PLAN'
    | 'DEVICE_DATA_CONNECTION_VARIANT'
    | 'DEVICE_DATA_INFRASTRUCTURE'
    | 'DEVICE_DATA_GRID_FEES_REDUCTION'
    | 'DEVICE_DATA_INSTALLER'
    | 'ADDRESS_PLANT_OPERATOR'
    | 'DOCUMENT_UPLOAD'
    | 'REQUEST_OVERVIEW'
    | 'NEXT_STEPS';

export type SubprocessMetaData = {
    processSubprocessIndex?: [number, number];
    path: string;
};

type SubprocessType = Record<SubprocessValue, SubprocessMetaData>;

export const SUBPROCESS: SubprocessType = {
    NOT_RESPONSIBLE: {
        path: NOT_RESPONSIBLE,
    },
    NO_CONTROLLABLE_CONSUMPTION_DEVICES_DETECTED: {
        path: NO_CONTROLLABLE_CONSUMPTION_DEVICES_DETECTED,
    },

    DEVICE_DATA_LOCATION_DATA: {
        processSubprocessIndex: [0, 0],
        path: DEVICE_DATA_LOCATION_DATA,
    },
    DEVICE_DATA_CONNECTION_PLAN: {
        processSubprocessIndex: [0, 1],
        path: DEVICE_DATA_CONNECTION_PLAN,
    },
    DEVICE_DATA_CONNECTION_VARIANT: {
        processSubprocessIndex: [0, 2],
        path: DEVICE_DATA_CONNECTION_VARIANT,
    },
    DEVICE_DATA_INFRASTRUCTURE: {
        processSubprocessIndex: [0, 3],
        path: DEVICE_DATA_INFRASTRUCTURE,
    },
    DEVICE_DATA_GRID_FEES_REDUCTION: {
        processSubprocessIndex: [0, 4],
        path: DEVICE_DATA_GRID_FEES_REDUCTION,
    },
    DEVICE_DATA_INSTALLER: {
        processSubprocessIndex: [0, 5],
        path: DEVICE_DATA_INSTALLER,
    },

    ADDRESS_PLANT_OPERATOR: {
        processSubprocessIndex: [1, 0],
        path: ADDRESS_PLANT_OPERATOR,
    },

    DOCUMENT_UPLOAD: {
        processSubprocessIndex: [2, 0],
        path: DOCUMENT_UPLOAD,
    },

    REQUEST_OVERVIEW: {
        processSubprocessIndex: [3, 0],
        path: REQUEST_OVERVIEW,
    },

    NEXT_STEPS: {
        processSubprocessIndex: [4, 0],
        path: NEXT_STEPS,
    },
} as const;
