import { APIRequestState } from '@/types/api';
import getCustomerType from '@/util/extractCustomerType';
import { AccountInfo } from '@azure/msal-browser';
import { filterInstallerData } from '@ten-netzkundenportal/ui-installer-selection';
import { InstallerFilterResultWithDecodedCompanyName } from '@ten-netzkundenportal/ui-installer-selection/build/types';
import * as React from 'react';

import { getInstallerId } from './getInstallerId';

export const useLoggedInInstallerData = (accounts: AccountInfo[]) => {
    const [lifecycleState, setLifecycleState] = React.useState<APIRequestState>('initial');
    const [installerData, setInstallerData] = React.useState<InstallerFilterResultWithDecodedCompanyName>(undefined);

    React.useEffect(() => {
        setLifecycleState('loading');
        if (accounts.length > 0) {
            const customerType = getCustomerType(accounts);
            if (customerType === 'installer') {
                const installerId = getInstallerId(accounts);
                const getInstallerData = () =>
                    filterInstallerData({
                        cityFilter: '',
                        electricityBranch: true,
                        gasBranch: false,
                        installerNameFilter: '',
                        postalCodeFilter: '',
                        installerId,
                    });
                getInstallerData()
                    .then((data) => {
                        if (data.length >= 1) {
                            setInstallerData(data[0]);
                        }

                        if (data.length === 0) {
                            console.error(`Installer with id=${installerId} could not be found.`);
                        }

                        if (data.length > 1) {
                            console.error(`Multiple Installer with id=${installerId} were found.`);
                        }
                        setLifecycleState('finished_successfully');
                    })
                    .catch((err) => {
                        console.error(err);
                        setLifecycleState('error');
                    });
                return;
            }
        }
        setInstallerData(undefined);
        setLifecycleState('finished_successfully');
    }, [accounts.length, accounts]);

    return { installerData, lifecycleState };
};
