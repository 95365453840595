import { OpenControllableConsumptionDevicesRegistration } from '@/api/getOpenControllableConsumptionDevicesRegistration';
import { InfrastructureDevice } from '@/types/infrastructure';
import { Context } from '@/wizard';
import { SUBPROCESS } from '@/wizard/constants';
import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import { InstallerFilterResultWithDecodedCompanyName } from '@ten-netzkundenportal/ui-installer-selection';

import { mapConnectionDataProperties } from './mapConnectionDataProperties';
import { mapEnergyStorageDevices } from './mapEnergyStorageDevices';
import { mapReferrerToOpenRegistrationInitiatedFrom } from './mapReferrerToOpenRegistrationInitiatedFrom';

const externalRequestFilter = (device: InfrastructureDevice) => !!device.fromExternalRequest;

export const contextMapper = (
    openRegistration: OpenControllableConsumptionDevicesRegistration,
    customerType: CustomerType,
    installerData: InstallerFilterResultWithDecodedCompanyName,
    location: Location,
): Partial<Context> => {
    const energyStorageDevices = mapEnergyStorageDevices(openRegistration.infrastructure.energyStorageDevices);
    const referrer = new URLSearchParams(location.search).get('referrer');

    return {
        meta: {
            registrationId: openRegistration.id,
            processCommunicationId: openRegistration.processCommunicationId,
            linkedToExternalRequest: true,
            furthestProcessSubProcessIndex: SUBPROCESS.DEVICE_DATA_CONNECTION_VARIANT.processSubprocessIndex,
            path: SUBPROCESS.DEVICE_DATA_CONNECTION_VARIANT.path,
            openRegistrationInitiatedFrom: mapReferrerToOpenRegistrationInitiatedFrom(referrer),
            installerIsPreselected: !!installerData,
        },
        customerType,
        module1_flatRateNetworkChargeReduction: true,
        location: openRegistration.location,
        chosenInstaller: openRegistration.chosenInstaller
            ? {
                  ...openRegistration.chosenInstaller,
                  companyNameDecoded: openRegistration.chosenInstaller.companyName,
              }
            : undefined,
        plantOperator: openRegistration.plantOperator,
        energyStorageDevices,
        isEnergyStorageAvailable: energyStorageDevices.length >= 1,
        defaultElectricityMeterNumber: energyStorageDevices?.find(externalRequestFilter)?.electricityMeter,
        defaultMeasurementLocationId: energyStorageDevices?.find(externalRequestFilter)?.measurementLocationId,
        ...mapConnectionDataProperties(openRegistration.connectionDataProperties),
    };
};
