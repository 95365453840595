import { RedirectToDashboard } from '@/RedirectToDashboard';
import { appConfig } from '@/app.config';
import { FinishedRegistrationRoot } from '@/finished-registration/FinishedRegistrationRoot';
import { FINISHED_REGISTRATION_ROOT, LANDING_PAGE, REQUEST_FORM_ROOT, START } from '@/routes';
import Start from '@/start/Start';
import { AuthenticatedContent, InitializingMsalProvider, msalConfig } from '@ten-netzkundenportal/ui-auth-utils';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './base.css';
import { LandingPage } from './landing-page/LandingPage';

const AppRouting = React.lazy(() => import(/* webpackPrefetch: true */ './app-routing.component'));

export default (): React.ReactElement => (
    <React.StrictMode>
        <InitializingMsalProvider msalConfig={msalConfig(appConfig)}>
            <Router>
                <Switch>
                    <Route exact path={START}>
                        <Start />
                    </Route>
                    <Route path={LANDING_PAGE}>
                        <AuthenticatedContent>
                            <LandingPage />
                        </AuthenticatedContent>
                    </Route>
                    <Route path={REQUEST_FORM_ROOT}>
                        <AppRouting />
                    </Route>
                    <Route path={FINISHED_REGISTRATION_ROOT}>
                        <AuthenticatedContent>
                            <FinishedRegistrationRoot />
                        </AuthenticatedContent>
                    </Route>

                    <Route component={RedirectToDashboard} />
                </Switch>
            </Router>
        </InitializingMsalProvider>
    </React.StrictMode>
);
