import { HintBox, StyledLink } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

export const Hints = () => (
    <div
        id="hints"
        className="flex flex-col lg:flex-row justify-center gap-y-4 gap-x-4 xl:gap-x-20 items-stretch pb-10"
    >
        <div className="flex-shrink-0 w-full md:w-[32rem] mx-auto">
            <HintBox
                title="Tipps für Privat- und Firmenkunden"
                checkLists={[
                    <span key="information">
                        Weitere Informationen zu{' '}
                        <StyledLink
                            href="https://www.thueringer-energienetze.com/Energiepartner/Netzkunden_und_Lieferanten_Stromnetz/Steuerbare_Verbrauchseinrichtungen"
                            target="_blank"
                        >
                            § 14a EnWG
                        </StyledLink>
                        .
                    </span>,
                    <span key="regulation">
                        Informieren Sie sich vorab über die{' '}
                        <StyledLink
                            href="https://www.dsc.bund.de/DE/Vportal/Energie/SteuerbareVBE/start.html?r=1"
                            target="_blank"
                        >
                            § 14a Modulauswahl zur Netzentgeltreduzierung
                        </StyledLink>
                        .
                    </span>,
                ]}
            />
        </div>

        <div className="flex-shrink-0 w-full md:w-[32rem] mx-auto">
            <HintBox
                title="Tipps für Installateure und Projektpartner"
                introductionText={<span>Erfragen Sie bitte vorab folgende Informationen:</span>}
                checkLists={[
                    <span>Einwilligung Ihres Kunden, dass Sie den Antrag stellen dürfen</span>,
                    <span>Anschrift Ihres Kunden</span>,
                    <span>E-Mail-Adresse Ihres Kunden</span>,
                ]}
            />
        </div>
    </div>
);
