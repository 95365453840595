import { isIndividualInfrastructureQualified, isInfrastructureGroupQualified } from '@/util/isInfrastructureQualified';

import { Context } from './context';

type ContextOnlyGuardParams = {
    context: Partial<Context>;
};

export const skipPlantOperatorPage = ({ context }: ContextOnlyGuardParams): boolean =>
    !!context?.meta?.linkedToExternalRequest && !!context.plantOperator;

export const showInstallerPage = ({ context }: ContextOnlyGuardParams): boolean =>
    !context?.meta?.installerIsPreselected;

export const showNotResponsiblePage = ({ context }: ContextOnlyGuardParams): boolean =>
    context.meta?.locationAvailability?.responsible === 'Other';

export const showNoControllableConsumptionDevices = ({ context }: ContextOnlyGuardParams): boolean => {
    const isHeatPumpOrRoomCoolingQualified = [context.heatPumpDevices, context.roomCoolingDevices].some((devices) =>
        isInfrastructureGroupQualified(devices),
    );

    if (isHeatPumpOrRoomCoolingQualified) {
        return false;
    }

    const isEnergyStorageOrChargingInfrastructureQualified = [
        ...context.energyStorageDevices,
        ...context.chargingInfrastructureDevices,
    ].some((device) => isIndividualInfrastructureQualified(device));

    return !isEnergyStorageOrChargingInfrastructureQualified;
};

export const isElectricityConnectionPointConnected = (context: Partial<Context>): boolean =>
    Boolean(context.electricityMarker);

export const guards = {
    showInstallerPage,
    showNotResponsiblePage,
    showNoControllableConsumptionDevices,
    skipPlantOperatorPage,
};
